
<template>
  <div>
    <vx-card title="Filter orders by status" class="user-list-filters mb-8">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-3">
                    <ul class="leftx sm:flex items-center">
                        <li class="mr-10">
                          <vs-radio v-model="defaultStatus" vs-value="all">All </vs-radio>
                        </li>
                        <li class="mr-10">
                            <vs-radio v-model="defaultStatus" vs-value="pending">Pending</vs-radio>
                        </li>
                        <li class="mr-10">
                            <vs-radio color="success" v-model="defaultStatus" vs-value="delivered">Delivered</vs-radio>
                        </li>
                        <li class="mr-10">
                            <vs-radio color="warning" v-model="defaultStatus" vs-value="completed">Completed</vs-radio>
                        </li>
                        <li class="mr-10">
                            <vs-radio color="#ffcaf0" v-model="defaultStatus" vs-value="revision">Revision</vs-radio>
                        </li>
                        <li class="mr-10">
                            <vs-radio color="danger" v-model="defaultStatus" vs-value="refunded">Refunded</vs-radio>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </vx-card>
    <div id="orders-table-loading" class="vs-con-loading__container">
      <vs-table ref="table" multiple v-model="selected" :data="orders">
        <div slot="header" class="flex mb-4 flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-grow flex-wrap-reverse items-center">
            <div class="flex">
              <select v-model="filter" @change="fetchOrders()"
                class="form-select mr-2" style="max-width:8rem;" placeholder="--filter--">
                <option selected value="" >--filter--</option>
                <option value="0"> Orders </option>
                <option value="1"> Offers </option>
                <option value="all"> All </option>
              </select>
            </div>
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="cursor-pointer mr-4">
              <div class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
                <span class="mr-2">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>No&nbsp;Action</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span v-if="pageData.total" class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ pageData.total - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : pageData.total }} of {{ pageData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 50">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 100">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 200">
                <span>200</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mb-0 mb-4 sm:w-auto w-full sm:order-normal order-3" 
          v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
        </div>

        <template slot="thead">
          <vs-th sort-key="id">Order ID</vs-th>
          <vs-th sort-key="buyer">Buyer</vs-th>
          <vs-th sort-key="product">Product</vs-th>
          <vs-th sort-key="created_at">Date</vs-th>
          <vs-th sort-key="delivery_time">Delivery Time</vs-th>
          <vs-th sort-key="is_workers_paid">Payment</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">
                  <router-link :to="`/orders/${tr.id}`" @click.stop.prevent class="text-inherit hover:text-primary"> #{{ tr.number }}</router-link>
                </p>
              </vs-td>

              <vs-td>
                <router-link :to="`/orders/${tr.id}`" @click.stop.prevent>
                  <p class="product-category" style="line-height:1;" v-if="tr.buyer">
                    <span class="block">{{ tr.buyer.first_name }} {{ tr.buyer.last_name }}</span>
                    <span style="color: #666; font-size: 10px;">{{ tr.business_name }}</span>
                  </p>
                </router-link>
              </vs-td>

              <vs-td>
                <p class="product-category" @click="navigateToRoute(tr.id)" v-if="tr.product"> {{ tr.product.name }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate" style="line-height:1;" @click="navigateToRoute(tr.id)">
                  <span class="block">{{ tr.created_at | date_time(1) }}</span>
                </p>
              </vs-td>
              <vs-td>
                <p class="product-category" @click="navigateToRoute(tr.id)">{{ tr.delivery_time | delivery_time }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate" style="line-height:1;">
                  <vx-tooltip v-if="tr.is_workers_paid" :text="'Date '+ getPaymentDate(tr.workers_paid_at)" position="top">
                    <vs-chip color="primary" class="product-order-status">paid</vs-chip>
                  </vx-tooltip>
                  <vs-chip v-else color="warning" class="product-order-status "> unpaid </vs-chip>
                </p>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        :total="pageData.last_page || 1"
        :max="7"
        v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

// Cell Renderer
export default {
  name:"order-list-table",
  data() {
    return {
      defaultStatus: "pending",
      filter: "all",
      searchQuery: "",
      selected: [],
    }
  },
   watch: {
    "$route.query":"refreshQueryStatus",
    defaultStatus (val) {
      this.fetchOrders()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.orderList.pageData
    },
    orders() {
      return this.pageData.data.filter((order) => this.defaultStatus == "all" 
            || (order.status == this.defaultStatus))
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchOrders(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchOrders(1, val)
      }
    },
    getPaymentDate(){
      return (date) => this.$options.filters.date_time(date)
    },
  },
  methods: {
    navigateToRoute(orderId){
      this.$router.push({path:`/orders/${orderId}`});
    },
    refreshQueryStatus(){
      if(this.$route.query.status){
          this.defaultStatus = this.$route.query.status
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchOrders()
    }, 2000),
    fetchOrders(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.filter != 'all'){ payload.is_offer = this.filter }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.$vs.loading({ container: '#orders-table-loading', color: "#444", type: "sound"})
      this.$store.dispatch("orderList/fetchOrders", payload)
      .then(response => {
        this.$vs.loading.close('#orders-table-loading > .con-vs-loading')
     })
    },
  },
  mounted() {
    this.refreshQueryStatus()
    if(this.defaultStatus == "pending"){this.fetchOrders()}
  },
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;


      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 60px;
              }
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }



    ::-webkit-scrollbar {
     width: 10px;
     margin-top:3rem;
     height: 10px;
    }

    ::-webkit-scrollbar-thumb {
     background: #22292f;
     border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
     background: #f8f8f8;
     border-radius: 20px;
    }

    .product-name {
      max-width: 23rem;
    }
  }
}
</style>
